/* eslint-disable react/prop-types */
import React from 'react'
import { ToastContainer, toast } from 'react-toastify';

function Toast() {
    return (
        <div>
            {toast(
                <div>
                    <div>Authentication Error, Please try logging again</div>
                </div>
            )}
            <ToastContainer
                position='top-right'
                autoClose={3000}
                hideProgressBar={true}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
            />
        </div>
    )
}

export default Toast