const stag = {
    API_URL: 'https://stag.rummy24.com/api/v1',
    SOCKET_URL: 'https://stag.rummy24.com/',
    GAME_URL: 'http://rummy24-game-phaser.s3-website.ap-south-1.amazonaws.com'
}

const prod = {
    API_URL: 'https://backend.rummy24.com/api/v1',
    GAME_URL: 'https://game.rummy24.com'
}


export default prod